<template>
  <div class="page">
    <navbar index="0" />

    <!-- banner begain -->
    <banner title="人力资源外包">
      <img src="../assets/img/banner_hr.jpg" class="img-fluid" alt="人力资源外包">
    </banner>
    <!-- banner end -->

    <div class="main-content">

      <!-- 服务 begain -->
      <div class="service-box">
        <section-title subtitle="覆盖了从战略、设计、开发、测试到维护的整个IT价值链">
          <slot>
            <span>S</span>ER<span>V</span>ICE/服务
          </slot>
        </section-title>
        <div class="service-content">
          <div class="service-item">
            <img src="../assets/img/service_1.jpg" class="img-fluid" alt="">
          </div>
          <div class="service-item">
            <img src="../assets/img/service_2.jpg" class="img-fluid" alt="">
          </div>
          <div class="service-item">
            <img src="../assets/img/service_3.jpg" class="img-fluid" alt="">
          </div>
          <div class="service-item">
            <img src="../assets/img/service_4.jpg" class="img-fluid" alt="">
          </div>
        </div>
      </div>
      <!-- 服务 end -->

      <!-- 联系我们 begain -->
      <contact />
      <!-- 联系我们 end -->
    </div>

    <foot />
  </div>
</template>

<script>
  import navbar from '@/components/navbar'
  import banner from '@/components/banner'
  import contact from '@/components/contact'
  import foot from '@/components/footer'
  import sectionTitle from '@/components/sectionTitle'
  export default {
    name: 'Hr',
    components: {
      navbar,
      banner,
      sectionTitle,
      contact,
      foot
    }
  }
</script>

<style lang="scss" scoped>
  .service-box {
    padding: 60px 0;
    .service-content {
      margin: 0 auto;
      margin-top: 100px;
      width: 1200px;
      display: flex;
      justify-content: space-between;
    }
  }
</style>